<div class="app-layout">
  <mat-toolbar color="primary" class="header-toolbar">
    <span class="app-name">Algosec-Portal</span>
    <span class="spacer"></span>
    <span class="user-info" [matMenuTriggerFor]="userMenu">{{getUserName()}}
    <mat-icon class="icon">
      arrow_drop_down
    </mat-icon></span>
    <mat-menu #userMenu="matMenu">
      <button  mat-menu-item (click)="logout()" >Abmelden</button>
    </mat-menu>
  </mat-toolbar>


  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav mode="side" fixedInViewport="true"
                 [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="!mobileQuery.matches"
                 [ngClass]="{'sidenav-collapsed': collapsedNav && !mobileQuery.matches}" class="sidenav"
                 (mouseenter)="onMouseEnter()"
                 (mouseleave)="onMouseLeave()">

      <button class="collapsed"
              mat-icon-button
              (click)="mobileQuery.matches ? sidenav.toggle() : ''"
              (click)="button = !button">
        <mat-icon> {{ !collapsedNav ? 'arrow_right' : 'arrow_left' }}</mat-icon>
      </button>




      <div class="fixed-content">
        <button *ngIf="!collapsedNav" (click)="button= false" mat-raised-button color="primary" [matMenuTriggerFor]="menu" class="new-request-button">
          <mat-icon>add</mat-icon>
          <span>New Request</span>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item routerLink="create-Rule">Firewall Rule</button>
          <button mat-menu-item routerLink="netzobjekt">Netz Object</button>
        </mat-menu>

        <mat-form-field *ngIf="!collapsedNav" class="search-field" appearance="outline">
          <mat-label>Go to Request...</mat-label>
          <input matInput>
          <button mat-button matSuffix>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <mat-nav-list>
        <ng-container *ngFor="let item of navItems">
          <mat-list-item [routerLink]="item.route" routerLinkActive="active">
            <mat-icon  class="icon">{{ item.icon }}</mat-icon>
            <span *ngIf="!collapsedNav">{{ item.label }}</span>
          </mat-list-item>
        </ng-container>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content [ngClass]="{'sidenav-collapsed': collapsedNav && !mobileQuery.matches}">
      <app-alert class="alert-header" #alert></app-alert>

      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
