import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import {environment} from "../../environments/environment";


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: environment.routes.createRule,
        loadChildren: () => import('../Regel/regel-erstellen/regel-erstellen.module').then(m => m.RegelErstellenModule),
      },
      {
        path: environment.routes.changeRule,
        loadChildren: () => import('../Regel/regel-aendern/regel-aendern.module').then(m => m.RegelAendernModule),
      },
      {
        path: environment.routes.deleteRule,
        loadChildren: () => import('../Regel/regel-loeshen/regel-loeshen.module').then(m => m.RegelLoeshenModule),
      },
      {
        path: environment.routes.netzobjekt,
        loadChildren: () => import('../netz-objekt/netz-objekt.module').then(m => m.NetzObjektModule),
      },
      {
        path: environment.routes.offenesTicket,
        loadChildren: () => import('../offenes-ticket/offenes-ticket.module').then(m => m.OffenesTicketModule),
      },
      {
        path: environment.routes.openRequests,
        loadChildren: () => import('../open-requests/open-requests.module').then(m => m.OpenRequestsModule),
      },
      {
        path: environment.routes.awatingresponse,
        loadChildren: () => import('../awating-response/awating-response.module').then(m => m.AwatingResponseModule),
      },
      {
        path: environment.routes.closedRequests,
        loadChildren: () => import('../closed-requests/closed-requests.module').then(m => m.ClosedRequestsModule),
      },
      {
        path: environment.routes.auswahltemplate,
        loadChildren: () => import('../auswahl-templates/auswahl-templates.module').then(m => m.AuswahlTemplatesModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {}
