export const environment = {
  production: true,
  api:{
    baseUrl:'https://algosec-connector-59028529-jhjx.dev.codemarket.ext-env.av360.org',
    tokenEndpoint:'/oauth2/token',
    authorizationEndpoint:'/oauth2/authorize',
    loginEndpoint:'/login'
  },
  routes:{
    createRule : 'create-Rule',
    changeRule: 'change-Rule',
    deleteRule: 'delete-Rule',
    netzobjekt: 'netzobjekt',
    offenesTicket: 'offenes-Ticket',
    openRequests: 'open-Requests',
    awatingresponse: 'awating-response',
    closedRequests: 'closed-Requests',
    auswahltemplate: 'auswahl-template'
  }
};
