import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { SharedModule } from "../Shared/shared.module";
import {HomeRoutingModule} from "./home-routing.module";
import {RegelErstellenModule} from "../Regel/regel-erstellen/regel-erstellen.module";
import {RegelAendernModule} from "../Regel/regel-aendern/regel-aendern.module";
import {RegelLoeshenModule} from "../Regel/regel-loeshen/regel-loeshen.module";
import {NetzObjektModule} from "../netz-objekt/netz-objekt.module";
import {OffenesTicketModule} from "../offenes-ticket/offenes-ticket.module";
import {OpenRequestsModule} from "../open-requests/open-requests.module";
import {AwatingResponseModule} from "../awating-response/awating-response.module";
import {ClosedRequestsModule} from "../closed-requests/closed-requests.module";
import {AuswahlTemplatesModule} from "../auswahl-templates/auswahl-templates.module";
import {AlertModule} from "../alert/alert.module";

@NgModule({
  declarations: [
    HomeComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    RegelErstellenModule,
    RegelAendernModule,
    RegelLoeshenModule,
    NetzObjektModule,
    OffenesTicketModule,
    OpenRequestsModule,
    AwatingResponseModule,
    ClosedRequestsModule,
    AuswahlTemplatesModule,
    AlertModule
  ]
})
export class HomeModule { }
